<template>
  <div class="paying">
    <div class="header flex flex-column cen-center m-bottom-md">
      <icon-font class="icon-font m-bottom-md" type="iconpaying"></icon-font>
      <div class="font-heading-2 m-bottom-sm">支付中</div>
      <div class="font-reverse-color-sub text-center">
        <span class="mobile-block">“激活音乐授权”后可以下载高清音频、授权书</span>
      </div>
    </div>

    <div class="container-xs">
      <div class="pay-info m-bottom-lg">
        <div class="m-bottom-md text-center">
          请向客服提交订单号和付款凭证截图，
          我们会帮助完成订单
        </div>

        <com-to-com-pay-info></com-to-com-pay-info>
      </div>

      <pay-result-music-content
        v-if="order_type === $val.orderType.music"
        :scope="curInfo"
        :status="$val.PayResultStatus.success"
        @success="onSuccess"
      ></pay-result-music-content>
      <pay-result-package-content v-else :scope="curInfo"></pay-result-package-content>
    </div>
  </div>
</template>

<script>
  import PayResultMusicContent from './result/PayResultMusicContent.vue'
  import PayResultPackageContent from './result/PayResultPackageContent.vue'
  import ComToComPayInfo from './components/ComToComPayInfo.vue'

  export default {
    data() {
      return {
        curInfo: {},
        order_id: this.$route.params.order_id,
        order_type: this.$route.query.order_type
      }
    },

    watch: {
      order_id: {
        handler(newVal) {
          this.$nextTick(() => {
            newVal && this.getOrderDetail({order_id: newVal});
          })
        },
        immediate: true
      }
    },

    computed: {
      orderInfoFunc() {
        let order_type = this.order_type;

        return order_type === this.$val.orderType.music ? this.$axios.OrderInfo : this.$axios.Package_orderInfo;
      }
    },

    components: {
      PayResultMusicContent, PayResultPackageContent,
      ComToComPayInfo
    },
    methods: {

      getOrderDetail(params) {
        this.$store.commit('changeContentLoading', true);

        this.orderInfoFunc(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.curInfo = data.data || {};
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      },

      onSuccess() {
        this.getOrderDetail({order_id: this.order_id})
      }
    }
  }
</script>

<style lang="scss" scoped>
  .paying {
    .header {
      height: 232px;
      color: #fff;
      padding: 40px 0;
      background-color: $primary-color;

      .icon-font {
        /deep/ {
          svg {
            width: 72px;
            height: 72px;
          }
        }
      }
    }

    // .pay-info {
    //   width: 660px;
    //   margin: 0 auto;
    // }
  }
</style>
